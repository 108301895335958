import Vue from "vue";
import Chart from "chart.js";
import * as ChartAnnotation from "chartjs-plugin-annotation";

import { Line, Bar, mixins, generateChart } from "vue-chartjs";

import { getScales } from "../libs/GraphHelper";
import verge from "verge";
import EventBus from "~/libs/EventBus.js";
const { reactiveProp } = mixins;

Chart.defaults.LineWithLine = Chart.defaults.line;
Chart.controllers.LineWithLine = Chart.controllers.line.extend({
  draw: function(ease) {
    try {
      Chart.controllers.line.prototype.draw.call(this, ease);
      if (this.chart.tooltip._active && this.chart.tooltip._active.length > 0) {
        var activePoint = this.chart.tooltip._active[0],
          ctx = this.chart.ctx,
          x = activePoint.tooltipPosition().x;
        let topY, bottomY;
        //console.log(activePoint.tooltipPosition().x, activePoint)
        //console.log(this.chart.tooltip)

        let yPositions = [];
        this.chart.tooltip._active.forEach((y) => {
          yPositions.push(y.tooltipPosition().y);
        });
        yPositions.sort(function(a, b) {
          return a - b;
        });

        var PI = Math.PI;

        var DOUBLE_PI = PI * 2;
        var radius = 10;

        // draw line
        ctx.save();
        ctx.beginPath();
        ctx.strokeStyle = "rgb(255,255,255,0.3)";
        ctx.lineWidth = 1.5;
        //ctx.arc(x, yPositions[0], radius, 0, DOUBLE_PI);
        for (let i = 0; i < yPositions.length; i++) {
          if (yPositions[i] + radius * 2 < yPositions[i + 1]) {
            ctx.moveTo(x, yPositions[i + 1] - radius);
            ctx.lineTo(x, yPositions[i] + radius);
          } else if (yPositions[i] > yPositions[i + 1] + radius * 2) {
            ctx.moveTo(x, yPositions[i + 1] + radius);
            ctx.lineTo(x, yPositions[i] - radius);
          }
          ctx.closePath();
          ctx.stroke();
          ctx.beginPath();

          ctx.arc(x, yPositions[i], radius, 0, DOUBLE_PI);
          ctx.closePath();
        }
        ctx.stroke();
        ctx.restore();
      }
    } catch (e) {}
  },
});

const CustomLine = generateChart("custom-line", "LineWithLine");
Chart.Tooltip.positioners.date = function(elements, eventPosition) {
  /** @type {Chart.Tooltip} */
  var tooltip = this;

  /* ... */
  let highestVal = 0;
  try {
    highestVal = Math.min(this._active[0]._model.y, this._active[1]._model.y);
    if (this._active[3]) {
      highestVal = Math.min(
        this._active[0]._model.y,
        this._active[2]._model.y,
        this._active[3]._model.y
      );
    }
  } catch (error) {
    console.error(error);
  }

  return {
    x: eventPosition.x,
    y: highestVal,
  };
};

let Graph24H = {
  extends: CustomLine,
  props: ["chartData", "options", "section"],
  watch: {
    section() {
      this.renderChart(this.chartData, this.options);
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
  methods: {
    customUpdate(chartData, options) {
      this.renderChart(chartData, options);
    },
    getYAxesMinMax() {
      return this.$data._chart.scales;
    },
    setAxis(axis) {
      let newOptions = Object.assign(this.options, {});
      newOptions.scales.yAxes = newOptions.scales.yAxes.map((y, idx) => {
        y.ticks.max = axis[idx] ? parseFloat(axis[idx].max, 10) : null
        y.ticks.min = axis[idx] ? parseFloat(axis[idx].min, 10) : null
        y.ticks.fontColor = this.$store.state.chart_store.cleanerDefaultColors[idx]
        return y;
        // return {
        //   ...oldAxis,
        //   ticks: {
        //     max: axis[idx] ? parseFloat(axis[idx].max, 10) : null,
        //     min: axis[idx] ? parseFloat(axis[idx].min, 10) : null,
        //     fontColor: this.$store.state.chart_store.cleanerDefaultColors[idx],
        //   },
        // };
      });
      this.renderChart(this.chartData, newOptions);
    },
  },
};

Vue.component("graph-24h", Graph24H);
